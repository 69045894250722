<template>
  <footer class="p-0">
    <div class="footer has-text-white p-0 pb-3">
      <div class="columns m-0 pb-5">
        <div class="column m-0 p-0 is-half is-light has-text-centered">
          <div class="tile notification is-primary is-borderless is-flex is-flex-direction-column p-5">
            <img src="../assets/images/KEL-logo-white.svg" alt="King Employment Law Logo" class="is-align-self-center footer-logo"/>
            <div class="has-text-centered is-align-self-center">
              <p class="mb-0">
                <b-icon
                    icon="map-marker"
                    size="is-small"
                    class="mr-2">
                </b-icon>1670 York St<br/> Denver, CO 80206
              </p>
              <p class="mb-0">
                <b-icon
                    icon="phone"
                    size="is-small"
                    class="mr-2">
                </b-icon>
                <b-icon
                    icon="fax"
                    size="is-small"
                    class="mr-2">
                </b-icon>
                <a href="tel:303-479-3997" class="is-not-underlined">(303) 479-3997</a>
              </p>
              <p class="mb-0">
                <b-icon
                    icon="email"
                    size="is-small"
                    class="mr-2">
                </b-icon>
                <a href="mailto:intake@kingemploymentlaw.com" class="is-not-underlined">intake@kingemploymentlaw.com</a>
              </p>
            </div>
          </div>
          <div class="columns m-0 is-dark is-mobile">
            <div class="column is-flex is-justify-content-start is-align-items-center is-flex-direction-column">
              <router-link to="/our-team" class=" has-text-white">Our Team</router-link>
              <router-link to="/news" class="mt-3 has-text-white">News</router-link>
              <router-link to="/practice-areas" class="mt-3 has-text-white">Practice Areas</router-link>
              <router-link to="/contact" class="mt-3 has-text-white">Contact</router-link>
            </div>
            <div class="column is-flex is-justify-content-start is-align-items-center is-flex-direction-column">
              <router-link to="/blog" class="has-text-white">Blog</router-link>
              <router-link to="/terms-of-use" class="mt-3 has-text-white">Terms of Use</router-link>
              <router-link to="/sitemap" class="mt-3 has-text-white">Sitemap</router-link>
              <a href="https://www.mycase.com/login/" target="_blank" class="mt-3 has-text-white">Client Portal</a>
            </div>
          </div>
        </div>
        <div class="column is-half p-5">
          <p class="modesto">Contact us for a consultation</p>
          <form
              id="contact-form"
              accept-charset="utf-8"
              class="is-fullwidth pr-1"
          >
            <input type="text" name="_honey" style="display:none">
            <input type="hidden" name="_subject" value="KEL Website Contact Form Submission">
            <fieldset>
              <b-field id="name-field">
                <b-input
                    required
                    v-model="name"
                    name="name"
                    type="text"
                    placeholder="Name"
                    aria-label="Name"
                    icon-pack="mdi"
                    class="is-fullwidth p-1 footer-input"/>
              </b-field>
              <b-field id="email-field" >
                <b-input
                    required
                    v-model="email"
                    type="email"
                    name="email"
                    class="is-fullwidth p-1 footer-input"
                    placeHolder="Email"
                    aria-label="Email"
                    icon-pack="mdi"
                    validation-message="Email address invalid"
                />
              </b-field>
              <b-field id="phone-field" >
                <b-input
                    required
                    v-model="phone"
                    type="tel"
                    name="phone"
                    class="is-fullwidth p-1 footer-input"
                    aria-label="Phone"
                    placeholder="Phone"
                    icon-pack="mdi"
                    validation-message="Phone number invalid"/>
              </b-field>
              <b-field id="message-field" >
                <b-input
                    required
                    v-model="message"
                    name="Message"
                    maxlength="2000"
                    rows="2"
                    type="textarea"
                    icon-pack="mdi"
                    placeholder="Please provide a summary of your case"
                    aria-label="Message"
                    class="is-fullwidth p-1 footer-input"
                />
              </b-field>
            </fieldset>
            <b-tooltip
                label="Fill out all fields before continuing"
                class="is-pulled-right is-radiusless animate__animated animate__fadeInUp _7"
                :active="formIncomplete"
                type="is-dark"
                id="submit-button"
                position="is-left"
            >
              <b-button
                  @click="goToContact"
                  :disabled="formIncomplete"
                  class="is-white is-pulled-right is-radiusless is-outlined"
              >Continue
              </b-button>
            </b-tooltip>
          </form>
        </div>
      </div>
      <div class="container has-text-centered pt-3 pb-5">
        <span>&copy; {{year}} King Employment Law. All rights reserved.</span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data(){
    return {
      name: '',
      email: '',
      phone: '',
      message: '',
      loading: false,
      year: new Date().getFullYear()
    }
  },
  computed: {
    formIncomplete() {
      return !this.name || !this.email || !this.message || !this.phone
    }
  },
  methods: {
    goToContact(){
      if(this.name) localStorage.setItem('name', this.name)
      if(this.email) localStorage.setItem('email', this.email)
      if(this.phone) localStorage.setItem('phone', this.phone)
      if(this.message) localStorage.setItem('message', this.message)
      this.name = ''
      this.email = ''
      this.phone = ''
      this.message = ''
      if(this.$route.name === 'contact'){
        window.location.reload()
      }else{
        this.$router.push({name: 'contact'})
      }
    }
  }
}
</script>

<style scoped>
</style>

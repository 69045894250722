<template>
  <div id="main">
    <a href="#content" class="is-sr-only">Skip to main content</a>
    <NavbarHome v-if="$route.name === 'home'" :is-mobile="isMobile"
                data-aos="fade-down"
                data-aos-delay="1000"
                data-aos-duration="1000"/>
    <Navbar v-else :is-mobile="isMobile"
            data-aos="fade-down"
            data-aos-delay="1000"
            data-aos-duration="1000"/>
    <router-view id="content" :news-cards="newsCards" :is-mobile="isMobile"/>
    <Footer/>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import NavbarHome from "@/components/NavbarHome";
import Footer from "@/components/Footer";

export default {
  name: 'Navbar.vue',
  components: {
    Navbar,
    NavbarHome,
    Footer
  },
  data() {
    return {
      isMobile: window.innerWidth < 1023,
      newsCards: [
        {
          title: 'CHSAA owes $2.5 million to Colorado Springs coach in race discrimination case, jury says',
          author: 'Lindsey Smith',
          mediaLogoSrc: 'https://m.bbb.org/prod/ProfileImages/f432904b-9354-4968-82da-9abd1c1776e8.jpeg?tx=w_150',
          mediaLogoAlt: 'Gazette Preps Logo',
          snippet: 'Jurors ruled the Colorado High School Activities Association should pay former Sand Creek assistant basketball coach $2.5 million in race discrimination case. William ‘Trey’ Harris III was hired by District 49 in 2016 as an assistant basketball coach at Sand Creek High School. The complaint alleges Harris was falsely blamed for a transfer violation and later fired as a “minority scapegoat,” according to court documents.',
          timestamp: '2021-04-29T14:15:00-06:00',
          link: 'https://gazette.com/high-school-sports/chsaa-owes-2-5-million-to-colorado-springs-coach-in-race-discrimination-case-jury-says/article_0fb9f562-a876-11eb-b356-7b0b58282e4b.html'
        },
        {
          title: 'JBS Swift to Pay up to $5.5 Million to Settle EEOC Race and Religious Discrimination Claim at Greeley Plant',
          author: 'EEOC.gov',
          mediaLogoSrc: 'https://www.eeoc.gov/themes/custom/eeoc_uswds/logo.svg',
          mediaLogoAlt: 'EEOC Logo',
          snippet: 'International Meat Processing Giant Agrees to Settle Claims That It Discriminated Against Employees Based on Race, National Origin and Religion. JBS USA LLC, doing business as JBS Swift & Company, will pay up to $5.5 million and provide other relief to end a lawsuit brought by the U.S. Equal Employment Opportunity Commission (EEOC) charging race, national origin, and religious discrimination at the company’s beef processing plant in Greeley, Colo., the EEOC announced today. ',
          timestamp: '2021-06-09T14:00:00-00:00',
          link: 'https://www.eeoc.gov/newsroom/jbs-swift-pay-55-million-settle-eeoc-race-and-religious-discrimination-claim-greeley-plant'
        },
        {
          title: 'Gender, age discrimination alleged against Jefferson-Como fire department',
          author: 'CBS Colorado',
          mediaLogoSrc: 'https://www.cbsnews.com/assets/show/colorado/logo-square-32.svg',
          mediaLogoAlt: 'CBS News Colorado Logo',
          snippet: 'A woman who has spent years volunteering for the Jefferson-Como Fire Protection District now claims age and gender discrimination prevented her from being hired full-time in a new Federal case filed Monday. Cynthia Winner spent years as a volunteer search and rescuer in Park County with a goal of becoming a full-time firefighter. After four years with the Park County Search and Rescue, she spent eight years volunteering with the Jefferson-Como Fire Protection District. In those eight years, the lawsuit says Winner logged 820 training hours, 175 call responses, multiple certifications, and passed every physical test she took. ',
          timestamp: '2022-12-20T12:45:00-0700',
          link: 'https://www.cbsnews.com/colorado/news/park-county-discrimination-jefferson-como-fire-protection-district-cynthia-winner/'
        },
        {
          title: 'Shown the Door, Older Workers Find Bias Hard to Prove',
          author: 'Elizabeth Olson',
          mediaLogoSrc: 'https://theme.zdassets.com/theme_assets/968999/d8a347b41db1ddee634e2d67d08798c102ef09ac.jpg',
          mediaLogoAlt: 'New York Times Logo',
          snippet: 'For more than four decades, manufacturing was the only work Donetta Raymond knew. Fresh from high school, she followed her father to the factory floor because, she said, “It was the best-paying job around. Starting as a sheet metal mechanic, Ms. Raymond found plenty of work in her hometown, Wichita, Kan., home to famous names in aviation like Cessna, Beech and Boeing.',
          timestamp: '2017-08-07T05:00:16-04:00',
          link: 'https://www.nytimes.com/2017/08/07/business/dealbook/shown-the-door-older-workers-find-bias-hard-to-prove.html'
        },
        {
          title: 'Leadville’s first Black police chief says city administrator, racist officers forced him from job ',
          author: 'Shelly Bradbury',
          mediaLogoSrc: 'https://www.denverpost.com/wp-content/uploads/2016/04/denverpost-square-logo.jpg?w=85',
          mediaLogoAlt: 'The Denver Post Logo',
          snippet: 'Leadville’s first Black police chief alleges he was driven from the job by racist officers and a city administrator who rallied together to force him out of the largely white police department, according to a newly filed federal lawsuit against the city.',
          timestamp: '2024-05-07T06:03:00-06:00',
          link: 'https://www.denverpost.com/2024/05/07/leadville-black-police-chief-racism-hal-edwards-lawsuit/?share=twecchtc20pcl0s0id0e'
        },
        {
          title: 'A Black police chief in Colorado files a racial discrimination lawsuit after his firing',
          author: 'Deon J. Hampton',
          mediaLogoSrc: 'https://upload.wikimedia.org/wikipedia/commons/f/f0/NBC_News_%282023%29.svg',
          mediaLogoAlt: 'NBC News',
          snippet: 'The Black former police chief of a small Colorado town says he was the victim of racial discrimination and fired after officers and city leaders worked to force him out even though he was cleared of wrongdoing by an independent investigator, according to a federal lawsuit.',
          timestamp: '2024-05-14T17:31:00-06:00',
          link: 'https://www.nbcnews.com/news/us-news/black-police-chief-colorado-files-racial-discrimination-lawsuit-firing-rcna152084'
        },
      ]
    }
  },
  created() {
    window.addEventListener("resize", this.handleWindowResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleWindowResize);
  },
  methods: {
    handleWindowResize() {
      this.isMobile = window.innerWidth < 1023
    }
  }
}
</script>

<style lang="scss">
@import "assets/scss-styles.scss";
</style>
<style>
#main {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#content {
  flex: 1 auto;
  margin-top: 5em;
}

.w-100{
  width: 100%;
}

a {
  display: inline-block;
  position: relative;
}

a:not(.button, .is-not-underlined)::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #3d3e89;
  transform-origin: bottom right;
  transition: transform 0.25s ease-in-out;
}

a.has-text-white::after {
  background-color: #c27b1f;
}

a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.list-style-circle {
  list-style: circle;
}

.list-style-circle li a {
  display: inline;
}

.list-style-circle > li > ul > li {
  list-style: disc;
  margin-left: 1.5em;
}

.is-borderless {
  border-radius: 0;
}

.is-not-underlined {
  text-decoration: none !important;
}

.footer-logo {
  max-height: 2em;
  margin-bottom: 1em;
}

.stat {
  font-size: 3.5em;
  color: #c27b1f;
  font-family: 'Amoret', sans-serif;
}

#home-background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  background-image: url("assets/images/people/group2_bw.png");

  background-color: rgba(61, 62, 137, 0.65);
  background-blend-mode: multiply;
  margin-top: -8.5em;
}

.home-title {
  margin-top: 7em;
}

.home-title svg {
  max-width: 35em;
  position: absolute;
}

.home-title-path {
  stroke-dasharray: 2088;
  stroke-dashoffset: 2088;
  animation: dash 1.5s ease-in-out forwards;
}

.home-title-path.delay-1 {
  stroke-dasharray: 1986;
  stroke-dashoffset: 1986;
  animation-delay: 0.35s;
}

.home-title-path.delay-2 {
  stroke-dasharray: 2314;
  stroke-dashoffset: 2314;
  animation-delay: 0.75s;
}

@keyframes dash {
  from {
    stroke-dashoffset: 1986;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.cls-1 {
  fill: none;
  stroke: #c17c2a;
  stroke-width: 2px;
  stroke-miterlimit: 10;
}

.cls-2 {
  font-size: 32.03px;
  fill: #fff;
  font-family: 'Amoret', sans-serif;
}

.cls-3 {
  letter-spacing: -0.05em;
}

.cls-4 {
  letter-spacing: -0.13em;
}

.cls-5 {
  letter-spacing: 0.03em;
}

.cls-6 {
  letter-spacing: -0.06em;
}

.cls-7 {
  letter-spacing: -0.01em;
}

.cls-8 {
  letter-spacing: -0.06em;
}

.cls-9 {
  letter-spacing: 0;
}

.cls-10 {
  letter-spacing: -0.03em;
}

.cls-11 {
  letter-spacing: -0.18em;
}

.home-card-link {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.home-card-notification {
  height: 100%;
  border: 2px solid white;
  background-color: rgba(61, 62, 137, 0.8);
  transition: 0.25s all;
  color: white;
}

.home-card-notification:hover {
  background-color: rgba(61, 62, 137, 1);
}

#home-description-section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-image: url("assets/images/art-deco-background-04.svg");
}

.has-no-opacity {
  opacity: 0;
  height: 0;
}

.is-small-icon {
  font-size: 0.875em;
  padding: 1.6955em;
}

.has-text-white strong {
  color: #fff;
}

.fa-1x {
  height: 1em !important;
}

.carousel-list.has-shadow {
  box-shadow: none !important;
}

.button::before, .button::after,
.draw-borders::before, .draw-borders::after {
  box-sizing: inherit;
  content: "";
  position: absolute;
  border-width: 1px;
  border-style: solid;
  transition: 0.3s all;
  border-color: transparent;
  width: 0;
  height: 0;
}

.draw-borders::before, .draw-borders::after {
  border-width: 2px;
}

.button::before {
  bottom: 7px;
  right: 7px;
}

.button::after {
  top: -7px;
  left: -7px;
}

.draw-borders::before {
  bottom: 13px;
  right: 13px;
}

.draw-borders::after {
  top: -13px;
  left: -13px;
}

.button:hover::before, .button:hover::after,
.draw-borders:hover::before, .draw-borders:hover::after {
  width: 100%;
  height: 100%;
}

.button:hover::before,
.draw-borders:hover::before {
  border-top-color: #c27b1f;
  border-right-color: #c27b1f;
  transition: height 0.1s ease-out, width 0.1s ease-out 0.1s;
}

.button:hover::after,
.draw-borders:hover::after {
  border-bottom-color: #c27b1f;
  border-left-color: #c27b1f;
  transition: border-color 0s ease-out 0.2s, height 0.1s ease-out 0.1s, width 0.1s ease-out 0.2s;
}

@media only screen and (max-width: 1023px) {
  .container {
    padding: 0 1em;
    max-width: 100% !important;
  }

  .columns {
    max-width: 100%;
  }

  .home-title svg {
    max-width: 90%;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1215px) {
  .container {
    max-width: 850px !important;
  }
}

@media only screen and (min-width: 1216px) and (max-width: 1407px) {
  .container {
    max-width: 1000px !important;
  }
}

@media only screen and (min-width: 1408px) {
  .container {
    max-width: 1100px !important;
  }

  #home-cards {
    padding: 0 2em;
  }
}

@media only screen and (min-width: 1550px) {
  #home-cards {
    padding: 0 10em;
  }
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Buefy from 'buefy'
import "@/assets/styles.css"
import AOS from 'aos';
import 'aos/dist/aos.css';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Buefy, {
  defaultIconPack: 'mdi'
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  mounted() {
    AOS.init({
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: 'ease-in-out',
    })
  }
}).$mount('#app')

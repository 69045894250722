<template>
  <b-navbar
      :fixed-top="true"
      :spaced="true"
      class="py-3 is-inverted"
      id="navbar"
      ref="navbar"
  >
    <template #brand>
      <div class="is-flex is-flex-grow-1 is-justify-content-space-between">
        <b-navbar-item tag="router-link" :to="{ path: '/' }" v-bind:class="{'p-4': !isMobile}">
          <img
              src="../assets/images/KEL-logo-mobile.svg"
              alt="King Employment Law Logo"
              class="logo"
              v-if="isMobile"
          >
          <img
              src="../assets/images/KEL-logo.svg"
              alt="King Employment Law Logo"
              class="logo"
              v-else
          >
        </b-navbar-item>
        <div v-if="isMobile" id="navbar-extras"
             class="has-no-opacity is-flex is-flex-direction-row is-align-items-center">
          <a class="has-text-right mr-3" href="tel:303-479-3997">
            <b-icon
                icon="phone"
                size="is-small"
                class="mr-2">
            </b-icon>
            <strong class=" has-text-primary ">Call today</strong>
            <br/>
            <strong class=" has-text-primary ">(303) 479-3997</strong>
          </a>
        </div>
      </div>
    </template>
    <template #end>
      <div class="is-flex is-flex-direction-column">
        <div class="has-text-right" v-if="!isMobile">
          <b-icon
              icon="phone"
              size="is-small"
              class="mr-2">
          </b-icon>
          <a class=" has-text-primary" href="tel:303-479-3997">Call today (303) 479-3997</a>
        </div>
        <div class="columns is-desktop pt-1 pb-2">
          <b-navbar-dropdown :hoverable="true" class="m-0 p-0">
            <b-navbar-item>
              <router-link :to="{path: '/our-team/diane-king'}" :active="$route.name==='our-team/diane-king'"
                           class="is-not-underlined">
                Diane King
              </router-link>
            </b-navbar-item>
            <b-navbar-item>
              <router-link :to="{path: '/our-team/marianna-mclean'}" :active="$route.name==='our-team/marianna-mclean'"
                           class="is-not-underlined">
                Marianna McLean
              </router-link>
            </b-navbar-item>
            <b-navbar-item>
              <router-link :to="{path: '/our-team/mary-mcclatchey'}" :active="$route.name==='our-team/mary-mcclatchey'"
                           class="is-not-underlined">
                Mary McClatchey
              </router-link>
            </b-navbar-item>
            <b-navbar-item>
              <router-link :to="{path: '/our-team/mary-shaw'}" :active="$route.name==='our-team/mary-shaw'"
                           class="is-not-underlined">
                Mary Shaw
              </router-link>
            </b-navbar-item>
            <b-navbar-item>
              <router-link :to="{path: '/our-team/kayla-smith'}" :active="$route.name==='our-team/kayla-smith'"
                           class="is-not-underlined">
                Kayla Smith
              </router-link>
            </b-navbar-item>
            <template #label>
              <router-link
                  class="button is-outlined is-primary is-radiusless navbar-dropdown-button mr-3"
                  :to="{ path: '/our-team' }"
                  :active="$route.name==='our-team'"
              >
                <span class="is-size-7">Our Team</span>
              </router-link>
            </template>
          </b-navbar-dropdown>
          <b-navbar-item tag="div" v-bind:class="{'py-2 mt-1': isMobile}" class="column">
            <router-link
                class="button is-outlined is-primary is-radiusless"
                :to="{ path: '/news' }"
                :active="$route.name==='news'"
            >
              <span class="is-size-7">News</span>
            </router-link>
          </b-navbar-item>
          <b-navbar-item tag="div" v-bind:class="{'py-2 mt-1': isMobile}" class="column">
            <router-link
                class="button is-outlined is-primary is-radiusless"
                :to="{ path: '/practice-areas' }"
                :active="$route.name==='practice-areas'"
            >
              <span class="is-size-7">Practice Areas</span>
            </router-link>
          </b-navbar-item>
          <b-navbar-item tag="div" v-bind:class="{'py-2 mt-1': isMobile}" class="column">
            <router-link
                class="button is-outlined is-primary is-radiusless"
                :to="{ path: '/contact' }"
                :active="$route.name==='contact'"
            >
              <span class="is-size-7">Contact</span>
            </router-link>
          </b-navbar-item>
          <b-navbar-item tag="div" v-bind:class="{'py-2 mt-1': isMobile}" class="column">
            <a
                class="button is-outlined is-primary is-radiusless"
                href="https://www.mycase.com/login/"
                target="_blank"
            >
              <span class="is-size-7">Client Portal</span>
            </a>
          </b-navbar-item>
        </div>
      </div>
    </template>
  </b-navbar>
</template>

<script>

export default {
  name: "Navbar",
  props: {
    isMobile: Boolean
  },
  data() {
    return {
      navbar: null,
      navbarExtras: null
    }
  },
  mounted() {
    this.navbar = document.getElementById('navbar')
    this.navbarExtras = document.getElementById('navbar-extras')
    this.growShrinkLogo()
    window.onscroll = () => {
      this.growShrinkLogo()
    }
    if (this.isMobile) {
      Array.from(document.querySelectorAll('.navbar-burger')).forEach(el => {
        el.addEventListener('click', () => {
          if (document.documentElement.scrollTop < 5) {
            this.navbar.classList.toggle('background')
            this.navbarExtras.classList.toggle('has-no-opacity')
          } else if (this.$route.name === 'faq') {
            document.getElementById('scrollToTop').classList.add('has-no-opacity')
          }
        })
      })
    }
  },
  methods: {
    growShrinkLogo() {
      document.querySelectorAll(".logo").forEach(img => {
        if (document.documentElement.scrollTop > 5) {
          img.classList.add('small')
          this.navbar.classList.remove('py-3')
          this.navbar.classList.add('py-1')
          this.navbar.classList.add('background')
          if (this.navbarExtras !== null) this.navbarExtras.classList.remove('has-no-opacity')
        } else {
          if (!this.$refs.navbar.$children[2].$el.classList.contains('is-active')) {
            this.navbar.classList.remove('background')
            if (this.navbarExtras !== null) this.navbarExtras.classList.add('has-no-opacity')
          }
          img.classList.remove('small')
          this.navbar.classList.add('py-3')
          this.navbar.classList.remove('py-1')
        }
      })
    }
  }
}
</script>

<style>
#navbar {
  background-color: transparent;
}

#navbar, #navbar-extras {
  transition: 0.25s all;
  z-index: 10;
}

.logo {
  height: 35px;
  transition: 0.25s all;
  max-height: unset !important;
}

.logo.small {
  height: 25px;
}

#navbar.background {
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
}

.navbar-menu.is-active {
  background-color: white;
}

.navbar-burger {
  color: #1d1c1c;
  align-self: center;
}

.navbar-menu.is-active .navbar-item {
  text-align: right;
}

.navbar-item {
  font-family: 'Amoret', sans-serif;
  font-size: 0.8em;
  font-weight: bold;
}


.navbar-item.has-dropdown {
  margin: 0 !important;
  padding: 0 !important;
}

.navbar-item.has-dropdown:hover,
.navbar-link.is-active:hover,
.navbar-item.has-dropdown:focus .navbar-link,
.navbar-item.has-dropdown:hover .navbar-link,
.navbar-item.has-dropdown.is-active .navbar-link {
  background-color: transparent !important;
}

a.navbar-link {
  padding-right: 0 !important;
}

a.navbar-link::after {
  display: none !important;
}

@media only screen and (max-width: 1023px) {
  .logo {
    height: 75px;
  }

  .logo.small {
    height: 60px;
  }
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import( '../views/HomeView.vue')
  },
  {
    path: '/our-team',
    name: 'our-team',
    component: () => import( '../views/AboutView.vue'),
    children: [
      {
        path: 'diane-king',
        name: 'diane-king',
        component: () => import('../views/DianeKing.vue')
      },
      {
        path: 'marianna-mclean',
        name: 'marianna-mclean',
        component: () => import('../views/MariannaMclean.vue')
      },
      {
        path: 'mary-mcclatchey',
        name: 'mary-mcclatchey',
        component: () => import('../views/MaryMcClatchey.vue')
      },
      {
        path: 'mary-shaw',
        name: 'mary-shaw',
        component: () => import('../views/MaryShaw.vue')
      },
      {
        path: 'kayla-smith',
        name: 'kayla-smith',
        component: () => import('../views/KaylaSmith.vue')
      },
    ]
  },
  {
    path: '/news',
    name: 'news',
    component: () => import( '../views/NewsView.vue')
  },
  {
    path: '/practice-areas',
    name: 'practice-areas',
    component: () => import( '../views/Services.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import( '../views/ContactView.vue')
  },
  {
    path: '/terms-of-use',
    name: 'terms-of-use',
    component: () => import( '../views/TermsOfUse.vue')
  },
  {
    path: '/sitemap',
    name: 'sitemap',
    component: () => import( '../views/SiteMap.vue')
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import( '../views/BlogView.vue')
  },
  {
    // path: "*",
    path: "/:catchAll(.*)*",
    name: 'ErrorView',
    component: () => import( '../views/ErrorView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior() {
    // always scroll to top
    return {
      y: 0,
      behavior: 'smooth'
    }
  }
})

export default router
